import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ForgottenPasswordModal from './ForgottenPasswordModal';
import { getTranslation, useTranslation } from 'utils/localization';
import A from 'components/layouts/A';
import { TNamespaceEnum } from 'types/types';
import styles from './ForgottenPasswordBlock.module.scss';

/**
 * State of the forgotten password modal.
 */
export enum ForgottenPasswordState {
    /**
     * Modal is shown on page load
     */
    SHOW = 'show',
}

const ForgottenPasswordBlock = (): ReactElement => {
    const { t } = useTranslation();
    const [showForgottenPasswordModal, setShowForgottenPasswordModal] =
        useState<boolean>(false);

    const router = useRouter();

    useEffect(() => {
        setShowForgottenPasswordModal(
            router.query?.resetPassword == ForgottenPasswordState.SHOW
        );
    }, []);

    return (
        <>
            {showForgottenPasswordModal && (
                <ForgottenPasswordModal
                    onHide={() => setShowForgottenPasswordModal(false)}
                />
            )}
            <div className={styles.forgottenPassword}>
                <A
                    href={undefined}
                    onClick={() => setShowForgottenPasswordModal(true)}
                >
                    {getTranslation(
                        t,
                        TNamespaceEnum.login,
                        'forgottenPassword'
                    )}
                </A>
            </div>
        </>
    );
};

export default ForgottenPasswordBlock;
