import React, { VFC } from 'react';
import LandingPage from 'components/login/LandingPage';
import { ServerSideInitProps } from 'types/types';
import { getTokenCookieFromContext } from 'utils/helpers/server/cookies';

import createClient from 'utils/helpers/server/create-client';
import { getRoute } from 'constants/routes';
import { setDisciplineServer } from 'utils/helpers/server/disciplineServer';
import {
    setMenuCollapsedServer,
    setMenuServer,
} from 'utils/helpers/server/menuServer';
import { getPageProps } from 'utils/helpers/server/serverSideProps/ssrProps';
import { setLangServer } from 'utils/helpers/server/langServer';
import { getPageNamespaces } from 'utils/helpers/server/serverSideProps/translations';
import { TokenPurpose } from 'utils/helpers/cookies.shared';

type Props = Record<string, never>;

interface ServerSideProps {
    props?: Props;
}

const Page: VFC<Props> = () => <LandingPage />;

export const getServerSideProps = async ({
    req,
    res,
    query,
}: ServerSideInitProps): Promise<ServerSideProps> => {
    const api = await createClient(req, res);

    // User is logged - redirect to home
    if (req && getTokenCookieFromContext(req, res, TokenPurpose.Refresh)) {
        console.log('redirect to home');
        res.writeHead(302, {
            Location: getRoute('/home', undefined, undefined, query),
        });
        res.end();
    }
    setDisciplineServer(req);
    setLangServer(req);
    setMenuServer(req);
    setMenuCollapsedServer(req);

    const props = await getPageProps<Props>({
        givenProps: {},
        req,
        res,
        api,
        query,
        nameSpaces: getPageNamespaces('/'),
        translatePage: true,
    });

    return {
        props,
    };
};

export default Page;
