import React, { ReactElement } from 'react';
import Button from 'components/forms/Button';
import { getTranslation, useTranslation } from 'utils/localization';
import H2 from 'components/typography/H2';
import { getRoute } from 'constants/routes';
import { TNamespaceEnum } from 'types/types';
import styles from './SignUp.module.scss';

const SignUp = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.signUpWrapper}>
            <H2 className={styles.title}>
                {getTranslation(t, TNamespaceEnum.landingPage, 'signUp')}
            </H2>
            <p className={styles.text}>
                {getTranslation(t, TNamespaceEnum.landingPage, 'signUpText')}
            </p>
            <Button href={getRoute('/signup')}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'signUpAction'
                ).toUpperCase()}
            </Button>
        </div>
    );
};

export default SignUp;
