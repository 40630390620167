import React, {
    ChangeEvent,
    useState,
    KeyboardEvent,
    ReactElement,
    useEffect,
} from 'react';
import ForgottenPasswordBlock from './forgotten_password/ForgottenPasswordBlock';
import Button from 'components/forms/Button';
import Input, { InputType } from 'components/forms/Input';
import User from 'components/icon/icons/user.svg';
import Unlock from 'components/icon/icons/unlock.svg';
import Card from 'components/card/Card';
import Loading from 'components/loading/Loading';
import { getTranslation, useTranslation } from 'utils/localization';
import H2 from 'components/typography/H2';
import { TNamespaceEnum } from 'types/types';
import styles from './LoginBlock.module.scss';

interface Props {
    loginCb: (email: string, password: string) => Promise<LoginError>;
    email?: string;
    showForgottenPasswordBlock?: boolean;
}

export interface LoginError {
    message: string;
    linkHref: string;
    linkContent: string;
}

export const LoginBlock = ({
    loginCb,
    showForgottenPasswordBlock,
    email: initEmail = '',
}: Props): ReactElement => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>(initEmail);
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<LoginError>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_EMAIL && process.env.NEXT_PUBLIC_PASSWORD) {
            setEmail(process.env.NEXT_PUBLIC_EMAIL);
            setPassword(process.env.NEXT_PUBLIC_PASSWORD);
        }
    }, []);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = async () => {
        if (!email || !password) {
            setError({
                message: getTranslation(
                    t,
                    TNamespaceEnum.login,
                    'error.reviewLogInDetails'
                ),
                linkHref: null,
                linkContent: null,
            });
            return;
        }

        const error = await loginCb(email, password);
        if (error) {
            setLoading(false);
            setError(error);
        }
    };

    return (
        <Card
            className={styles.card}
            badge={{
                icon: User,
                background: 'info',
            }}
            classNameBadge={styles.cardBadge}
        >
            {loading ? (
                <Loading
                    loadingText={getTranslation(
                        t,
                        TNamespaceEnum.login,
                        'loading'
                    )}
                />
            ) : (
                <div className={styles.cardContent}>
                    <H2 className={styles.title}>
                        {getTranslation(t, TNamespaceEnum.login, 'logIn')}
                    </H2>
                    {error && (
                        <span className={styles.error}>
                            {error.message}
                            {error?.linkContent && (
                                <a href={error.linkHref}>{error.linkContent}</a>
                            )}
                        </span>
                    )}
                    <Input
                        value={email}
                        onChange={handleEmailChange}
                        icon={User}
                        placeholder={getTranslation(
                            t,
                            TNamespaceEnum.login,
                            'yourEmail'
                        )}
                        className={styles.input}
                        type={InputType.email}
                        onKeyDown={handleKeyDown}
                        autocomplete="username"
                        withBottomBorder
                    />
                    <Input
                        value={password}
                        onChange={handlePasswordChange}
                        icon={Unlock}
                        placeholder={getTranslation(
                            t,
                            TNamespaceEnum.login,
                            'password'
                        )}
                        className={styles.input}
                        type={InputType.password}
                        onKeyDown={handleKeyDown}
                        autocomplete="current-password"
                        withBottomBorder
                    />
                    {showForgottenPasswordBlock && <ForgottenPasswordBlock />}
                    <Button className={styles.button} onClick={handleLogin}>
                        {getTranslation(
                            t,
                            TNamespaceEnum.login,
                            'action'
                        ).toUpperCase()}
                    </Button>
                </div>
            )}
        </Card>
    );
};

export default LoginBlock;
