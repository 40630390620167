import React, { ReactElement } from 'react';
import Button from 'components/forms/Button';
import { getTranslation, useTranslation } from 'utils/localization';
import H2 from 'components/typography/H2';
import { getRoute } from 'constants/routes';
import { TNamespaceEnum } from 'types/types';
import styles from './PersonRecovery.module.scss';

/**
 * Person recovery box on homepage.
 */
const PersonRecovery = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <H2 className={styles.title}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'personRecovery'
                )}
            </H2>
            <p className={styles.text}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'personRecoveryText'
                )}
            </p>
            <Button href={getRoute('/person-recovery')}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'personRecoveryAction'
                ).toUpperCase()}
            </Button>
        </div>
    );
};

export default PersonRecovery;
