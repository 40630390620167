import React, { ReactElement } from 'react';
import { getTranslation, useTranslation } from 'utils/localization';
import Login from 'components/login/Login';
import SignUp from 'components/login/SignUp';
import AppLayout from 'components/layouts/AppLayout';
import H3 from 'components/typography/H3';
import { TNamespaceEnum } from 'types/types';
import RenewMembership from 'components/login/RenewMembership';
import PersonRecovery from 'components/login/PersonRecovery';

import styles from './Landing.module.scss';

const LandingPage = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <AppLayout
            title={t('general.defaultTitle')}
            pageTitle={{
                primaryBoldText: t('general.welcome'),
                isDark: true,
            }}
            showDisciplineMenu
            hideLoginAs
            showLangMenu={true}
            displayPermanentAlert={true}
        >
            <div>
                <H3 className={styles.secondTitle}>
                    {getTranslation(t, TNamespaceEnum.landingPage, 'text')}
                </H3>
                <div className={styles.cardsWrapper}>
                    <Login />
                    <div className={styles.cardsWrapperInner}>
                        <SignUp />
                        <PersonRecovery />
                        <RenewMembership />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default LandingPage;
