import React, { ReactElement } from 'react';
import Button from 'components/forms/Button';
import { getTranslation, useTranslation } from 'utils/localization';
import H2 from 'components/typography/H2';
import { getRoute } from 'constants/routes';
import { TNamespaceEnum } from 'types/types';
import styles from './RenewMembership.module.scss';

/**
 * Renew membership block.
 */
const RenewMembership = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <H2 className={styles.title}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'renewMembership'
                )}
            </H2>
            <p className={styles.text}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'renewMembershipText'
                )}
            </p>
            <Button href={getRoute('/renew-membership')}>
                {getTranslation(
                    t,
                    TNamespaceEnum.landingPage,
                    'renewMembershipAction'
                ).toUpperCase()}
            </Button>
        </div>
    );
};

export default RenewMembership;
