import React, { ReactElement, useMemo } from 'react';
import { TFunction } from 'next-i18next';
import EditForm from 'components/editForm/EditForm';
import {
    FilterSize,
    GroupEditFieldInterface,
    LabelSize,
} from 'components/interfaces/GeneralInterface';
import Modal from 'components/modal/Modal';
import {
    FieldTypeApiEnum,
    ValuesStringInterface,
} from 'submodules/api_middleware';
import { FormResponseData, UserService } from 'services/user.service';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import {
    getTranslation,
    getTranslationKey,
    useTranslation,
} from 'utils/localization';
import { validateEmail } from 'utils';
import { OnSuccessType } from 'components/interfaces/EditFormInterface';
import { TNamespaceEnum } from 'types/types';

interface Props {
    onHide: () => void;
}

const ForgottenPasswordModal = ({ onHide }: Props): ReactElement => {
    const { t } = useTranslation();

    const fields = useMemo<GroupEditFieldInterface[]>(
        (): GroupEditFieldInterface[] => getFieldWithLocalization(t),
        []
    );

    const onSubmit = (
        values: ValuesStringInterface
    ): Promise<FormResponseData> => {
        const resetUrl = `${location.origin}/password-reset`;
        return new UserService().requestPasswordReset({
            email: values.email,
            reset_url: resetUrl,
        });
    };

    const onSuccess = (): OnSuccessType => {
        pushMessage(
            'forgotten_password_request_message',
            MessageType.SUCCESS,
            'forgotten_password_request_title',
            false
        );
        onHide();
    };

    return (
        <Modal
            show={true}
            onHide={onHide}
            showCross
            title={getTranslationKey(TNamespaceEnum.login, 'forgottenPassword')}
            size={'S'}
        >
            <EditForm
                fields={fields}
                submit={onSubmit}
                onSuccess={onSuccess}
                withCancelChangesBtn
                buttonTextKey={getTranslationKey(
                    TNamespaceEnum.login,
                    'recoverPassword'
                )}
                cancelButtonTextKey={'general.cancel'}
                cancel={onHide}
                centerButtons
            />
        </Modal>
    );
};

export default ForgottenPasswordModal;

const getFieldWithLocalization = (t: TFunction): GroupEditFieldInterface[] => {
    return [
        {
            fields: [
                {
                    name: 'email',
                    label: 'address.email',
                    placeholder: '@',
                    type: FieldTypeApiEnum.TEXT,
                    size: FilterSize.FULL,
                    labelSize: LabelSize.TOP,
                    required: true,
                    validate: (value) => validateEmail(t, value as string),
                    tooltip: getTranslation(
                        t,
                        TNamespaceEnum.login,
                        'recoverPassword-tooltip'
                    ),
                    autoFocus: true,
                },
            ],
        },
    ];
};
